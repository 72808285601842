import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/E-Builder.png'

function Header() {
  const [top, setTop] = useState(true)

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && 'bg-white blur shadow-lg'
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div>
            {/* Logo */}
            <Link to="/">
              <img
                style={{
                  opacity: '0.8',
                  maxWidth: '40%',
                  borderRadius: '12px',
                }}
                height="100%"
                width="100%"
                src={logo}
                alt="react-logo"
              />
            </Link>
          </div>

          {/* Site navigation  href={`https://etherscan.io/token/`} target="_blank"
            rel="noopener noreferrer"
          <Link
            to="/comingsoon"
            style={{
              height: '50%',
              textAlign: 'justify',
              verticalAlign: 'super',
              borderRadius: '12px',
            }}          
            className="btn-sm text-blue-200 bg-blue-800 hover:bg-blue-700"
          >
            Contact
          </Link>
*/}
          <a
            className="btn-sm text-blue-200 bg-blue-800 hover:bg-blue-700"
            style={{
              height: '50%',
              textAlign: 'justify',
              verticalAlign: 'super',
              borderRadius: '35px 35px',
            }} 
            target="_blank"  
            rel="noopener noreferrer no-referrer-when-downgrade"           
            href="https://forms.gle/LiMLzMW53CTLxLL87"
          >
            Contact
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
