import React from 'react'
import { Link } from 'react-router-dom'

import Header from '../partials/Header'

function PageComingSoon() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
<br></br>
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Coming soon...</h1>
              </div>


              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h3">Please be patience with us.</h1>
              </div>
               <div className="text-gray-600 text-center mt-6">
                  <Link
                    to="/"
                    className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                  >
                    Return back to home page.{' '}
                  </Link>
                </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default PageComingSoon
