import React from 'react'

import Header from '../partials/Header'
import HeroHome from '../partials/HeroHome'
import FeaturesHome from '../partials/Features'
import FeaturesBlocks from '../partials/FeaturesBlocks'
//import Testimonials from '../partials/Testimonials';
//import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer'
import arrow from '../images/up-arrow.svg'

function Home() {

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor'
    )

    if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
}

  return (
    <div id="back-to-top-anchor" className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        <FeaturesBlocks />

        {/*  <Testimonials />*/}
        {/*  <Newsletter /> */}
      </main>

      {/*  Site footer */}
      {<Footer />}
      <div
        className="flex justify-center items-center bg-white rounded-full"
        style={{
          position: 'fixed',
          bottom: '80px',
          float: 'right',
          left: '80vw',
          maxWidth: '40px',
          width: '100%',
          maxHeight: '30px',
          height: '100%',
          fontSize: '30px',
          cursor:'pointer'
        }}
        onClick={handleClick}
        >
        <img src={arrow} height="100%"
          width="100%" alt="React Logo" />
      </div>
    </div>
  )
}

export default Home
