import React, { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import './css/style.scss'

import AOS from 'aos'

import Home from './pages/Home'
import PageComingSoon from './pages/PageComingSoon'
import Page404 from './pages/Page404'
//import ResetPassword from './pages/ResetPassword'
import ReactGA from "react-ga"


const TRACKING_ID = "UA-127431209-1"

function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
/*
function sendEvent(payload) {
 ReactGA.event(payload)
}*/

function sendPageview(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

function App() {
  const location = useLocation()

  if (process.env.NODE_ENV !== 'development') {
    console.log = () => { }
    console.warn = () => { }
    if (window.location.protocol !== 'https:') {
      window.location.protocol = 'https:'
    }
  }

  useEffect(() => {
    init();

    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
  })

  useEffect(() => {
    const currentPath = location.pathname + location.search
    sendPageview(currentPath)
  }, [location])

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]) // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/comingsoon">
          <PageComingSoon />
        </Route>
        <Route path="/404">
          <Page404 />
        </Route>
       <Route path="/*">
          <Page404 />
        </Route>
      </Switch>
    </>
  )
}

export default App
